import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import RecentPost from "../components/RecentPost";

const TagRoute = (props) => {
  const posts = props.data.allMarkdownRemark.edges;

  const { tag } = props.pageContext;
  const { title } = props.data.site.siteMetadata;

  return (
    <Layout>
      <Helmet title={`${tag} | ${title}`} />
      <h2 className="pb-8 px-4 md:px-16 xl:px-32 font-poppins font-semibold text-2xl">{`${tag} (${props.data.allMarkdownRemark.totalCount})`}</h2>
      <div className="px-4 md:px-16 xl:px-32">
        {posts &&
          posts.map(({ node: post }, index) => (
            <div className="" key={post.frontmatter.title}>
              {(index % 2 === 0 && (
                <RecentPost postData={post} justify="left" />
              )) || <RecentPost postData={post} justify="right" />}
            </div>
          ))}
      </div>
    </Layout>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, mainTag: { eq: $tag } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            mainTag
            date(formatString: "MMMM DD, YYYY")
            description
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
    }
  }
`;
